import React from "react";
import { Control, Controller, FieldErrors, useFieldArray, UseFormRegister } from "react-hook-form";
import { EditTenderFormData } from "./EditTenderForm";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { NIL, v4 as uuidv4 } from "uuid";
import { Trans, useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { TenderQuantityUnits } from "../../../../definitions/model/ServiceBook";
import LabeldCard from "../../../../components/Base/LabeldCard";

type TenderSectionItemProps = {
  hideCo2?: boolean;
  archive?: boolean;
  control: Control<EditTenderFormData, any>;
  register: UseFormRegister<EditTenderFormData>;
  errors: FieldErrors<EditTenderFormData>;
  sectionIndex: number;
};

const TenderSectionItems = ({ hideCo2, archive, control, register, errors, sectionIndex }: TenderSectionItemProps) => {
  const { t } = useTranslation("translation");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `Sections.${sectionIndex}.Items`,
  });

  const TenderItemType = {
    None: "None",
    Electricity: "Electricity",
    Plumbing: "Plumbing",
    Heating: "Heating",
    Roof: "Roof",
    Facade: "Facade",
    Windows: "Windows",
    Doors: "Doors",
    Floor: "Floor",
    Kitchen: "Kitchen",
    Bathroom: "Bathroom",
    LivingRoom: "LivingRoom",
    Bedroom: "Bedroom",
    Hallway: "Hallway",
    Basement: "Basement",
    Balcony: "Balcony",
    Garage: "Garage",
    Other: "Other",
  };

  return (
    <Card>
      <CardContent>
        {fields.map((item, itemIndex) => (
          <Card key={item.id} variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={9}>
                  <TextFieldConnector
                    register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Name`, { required: true })}
                  >
                    <TextField
                      label={t("ServiceBook.Tender.Name")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Name}
                      disabled={archive}
                    />
                  </TextFieldConnector>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={1} alignContent="flex-end">
                  {!archive && (
                    <IconButton onClick={() => remove(itemIndex)}>
                      <Delete />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextFieldConnector
                  register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Description`, { required: true })}
                >
                  <TextField
                    label={t("ServiceBook.Tender.Description")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    variant="outlined"
                    error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Description}
                    disabled={archive}
                  />
                </TextFieldConnector>
              </Grid>
              <Grid item xs={12} mt={1}>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="type-field-label">{t("ServiceBook.Tender.ItemType.Name")}</InputLabel>
                  <Controller
                    name={`Sections.${sectionIndex}.Items.${itemIndex}.Type`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="type-field-label"
                        variant="outlined"
                        label={t("ServiceBook.Tender.ItemType.Name")}
                        disabled={archive}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      >
                        {Object.values(TenderItemType).map((type) => (
                          <MenuItem
                            key={type}
                            value={type === TenderItemType.None ? "" : type}
                            style={{ padding: "10px 20px" }}
                          >
                            {t(`ServiceBook.Tender.ItemType.${type}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.Tender.Materials")}>
                  <Grid item xs={12} mt={1}>
                    <TextFieldConnector
                      register={register(`Sections.${sectionIndex}.Items.${itemIndex}.Quantity`, { required: true })}
                      sx={{ "& .MuiInputBase-root": { paddingRight: 0 } }}
                    >
                      <TextField
                        label={t("ServiceBook.Tender.Quantity")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.Quantity}
                        disabled={archive}
                        InputProps={{
                          endAdornment: (
                            <FormControl
                              fullWidth
                              margin="dense"
                              sx={{
                                margin: 0,
                                width: "auto",
                                minWidth: 85,
                                "& .MuiFormLabel-root": { background: "white", marginTop: "1px" },
                                "& .MuiSelect-select.MuiInputBase-input": { textOverflow: "unset", paddingRight: 5 },
                              }}
                            >
                              <InputLabel id="quantity-units-field-label">
                                {t("ServiceBook.Tender.QuantityUnits")}
                              </InputLabel>
                              <Controller
                                name={`Sections.${sectionIndex}.Items.${itemIndex}.QuantityUnits`}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    labelId="quantity-units-field-label"
                                    variant="outlined"
                                    label={t("ServiceBook.Tender.QuantityUnits")}
                                    sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                    disabled={archive}
                                    onChange={(e) => {
                                      field.onChange(+e.target.value);
                                    }}
                                  >
                                    {Object.entries(TenderQuantityUnits)
                                      .filter(([key, val]) => !Number.isInteger(val))
                                      .map(([key, val]) => (
                                        <MenuItem key={key} value={key} style={{ padding: "10px 20px" }}>
                                          {t(`ServiceBook.Tender.QuantityUnits.${val}`)}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                )}
                                control={control}
                              />
                            </FormControl>
                          ),
                        }}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextFieldConnector
                      register={register(`Sections.${sectionIndex}.Items.${itemIndex}.PricePerItem`, {
                        required: true,
                      })}
                    >
                      <TextField
                        label={t("ServiceBook.Tender.PricePerItem")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.PricePerItem}
                        disabled={archive}
                      />
                    </TextFieldConnector>
                  </Grid>
                  {!hideCo2 && (
                    <Grid item xs={12} mt={1}>
                      <TextFieldConnector
                        register={register(`Sections.${sectionIndex}.Items.${itemIndex}.CO2EmissionPerItem`, {
                          required: true,
                        })}
                      >
                        <TextField
                          label={
                            <Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2EmissionPerItem" />
                          }
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          variant="outlined"
                          error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.CO2EmissionPerItem}
                          disabled={archive}
                        />
                      </TextFieldConnector>
                    </Grid>
                  )}
                </LabeldCard>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LabeldCard label={t("ServiceBook.Tender.Hours")}>
                  <Grid item xs={12} mt={1}>
                    <TextFieldConnector
                      register={register(`Sections.${sectionIndex}.Items.${itemIndex}.WorkHours`, { required: true })}
                    >
                      <TextField
                        label={t("ServiceBook.Tender.WorkHours")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.WorkHours}
                        disabled={archive}
                      />
                    </TextFieldConnector>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextFieldConnector
                      register={register(`Sections.${sectionIndex}.Items.${itemIndex}.WorkHourPrice`, {
                        required: true,
                      })}
                    >
                      <TextField
                        label={t("ServiceBook.Tender.WorkHourPrice")}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        variant="outlined"
                        error={!!errors.Sections?.[sectionIndex]?.Items?.[itemIndex]?.WorkHourPrice}
                        disabled={archive}
                      />
                    </TextFieldConnector>
                  </Grid>
                </LabeldCard>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </CardContent>
      <CardActions>
        {!archive && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              append({
                Id: uuidv4(),
                Name: "",
                Type: "",
                Description: "",
                MaterialId: NIL,
                PricePerItem: 0,
                CO2EmissionPerItem: 0,
                Quantity: 0,
                QuantityUnits: TenderQuantityUnits.None,
                WorkHours: 0,
                WorkHourPrice: 0,
              });
            }}
          >
            {t("ServiceBook.Tender.AddItem")}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default TenderSectionItems;
