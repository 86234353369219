import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { OwnedUnitDto } from "../../../definitions/model/unit/Unit";
import NoPhoto from "../../../assets/img/no_house.jpg";
import { useDoubleClick, useTranslatedGroupRoles } from "../../../helpers/hooks";
import { GroupMemberRole, UserType } from "../../../constants/enums";
import { useTranslation } from "react-i18next";
import UnitHelper from "../../../helpers/unit-helper";
import { useUser } from "../../../actions/user.actions";

const StyledCard = styled(Card, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "isMovedIn" && propName !== "currentHouse",
})<{ isMovedIn: boolean; currentHouse: boolean }>(({ theme, isMovedIn, currentHouse }) => ({
  transition: "border-color 0.3s",
  cursor: isMovedIn ? "pointer" : "initial",
  height: "100%",
  borderColor: currentHouse ? theme.palette.primary.main : theme.palette.grey[400],
  "&:hover": {
    borderColor: theme.palette.grey[600],
  },
}));

const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: (propName: string) => propName !== "imageUrl",
})<{ imageUrl: string; component: React.ReactNode }>(({ imageUrl }) => ({
  backgroundImage: `url('${imageUrl}'), url('${NoPhoto}')`,
  paddingTop: 155,
}));

type HouseCardProps = {
  unit: OwnedUnitDto;
  onFamilyClick: (id: string) => void;
  onChnageCurrentHouse: (id: string) => Promise<void>;
  onMoveOut: (unitId: string, hasCo2Plan: boolean) => void;
};

const HouseCard = ({ unit, onFamilyClick, onChnageCurrentHouse, onMoveOut }: HouseCardProps): JSX.Element => {
  const isCardDisabled = unit.CurrentUserRole === GroupMemberRole.NotValidMember;

  const { t } = useTranslation("translation");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [{ user }] = useUser();
  const { getDisplayGroupRole } = useTranslatedGroupRoles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const handleFamilyClick = (unitId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if ((!unit.IsRental && !isCardDisabled) || user?.Role === UserType.Administrator) onFamilyClick(unitId);
  };

  const handleChangeHouseClick = (unitId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onChnageCurrentHouse(unitId);
    handleClose(e);
  };

  const handleMoveOut = (unitId: string, hasCo2Plan: boolean) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onMoveOut(unitId, hasCo2Plan);
    handleClose(e);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onCardClick = isCardDisabled
    ? () => {}
    : useDoubleClick(handleFamilyClick(unit.Id), handleChangeHouseClick(unit.Id), isXs ? 600 : 300);

  const address = UnitHelper.GetFullAddress(unit.Address, false);

  return (
    <StyledCard isMovedIn={isCardDisabled} currentHouse={unit.IsCurrentHouse} variant="outlined" onClick={onCardClick}>
      <CardHeader
        title={address}
        subheader={`${unit.Address.PostalCode} ${unit.Address.Commune.CommuneName}`}
        action={
          <IconButton size="small" onClick={handleClick}>
            <MoreVert />
          </IconButton>
        }
      />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!unit.IsCurrentHouse && (
          <MenuItem sx={{ padding: "4px" }} disabled={isCardDisabled} onClick={handleChangeHouseClick(unit.Id)}>
            {t("HouseManagement.SetAsCurrent")}
          </MenuItem>
        )}
        {!unit.IsRental && (
          <MenuItem sx={{ padding: "4px" }} onClick={handleMoveOut(unit.Id, unit.HasCO2Plan)}>
            {t("HouseManagement.MoveOut")}
          </MenuItem>
        )}
      </Menu>

      <StyledCardMedia imageUrl={unit.Photo} component="div" />
      <CardContent>
        <Typography>
          {t("HouseManagement.HouseOwner")}:{" "}
          {user?.Name === unit.OwnerName ? t("General.Management.You") : unit.OwnerName}
        </Typography>
        <Typography>
          {t("General.Management.Role")}: {getDisplayGroupRole(unit.CurrentUserRole)}
        </Typography>
        <Typography>{!isCardDisabled && `${t("General.Management.Members")}: ${unit.MembersCount}`}</Typography>
        {Boolean(unit.NotApprovedMembers) && !isCardDisabled && (
          <Typography>
            {t("General.Management.Pending")}: {unit.NotApprovedMembers}
          </Typography>
        )}
        <Grid container justifyContent="center" sx={{ marginTop: "18px" }}>
          {unit.IsCurrentHouse && (
            <Chip
              sx={{ fontSize: 12 }}
              variant="outlined"
              size="small"
              label={t("HouseManagement.SelectedHouse")}
              color="secondary"
            />
          )}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default HouseCard;
