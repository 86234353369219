import React, { useState, useEffect, SyntheticEvent } from "react";
import { useForm } from "react-hook-form";
import {
  Typography,
  Box,
  Grid,
  Link,
  useMediaQuery,
  InputLabel,
  TextField,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import PasswordField from "../components/Base/PasswordField";
import AutoComplete from "../components/Autocomplete/AutoComplete";
import { emailRegexp } from "../services/validation.service";
import DataPoliticDialog from "../components/Dialogs/DataPoliticDialog";
import { UserInterest } from "../constants/enums";
import { CompleteRegistrationData, UserInterestsFormData } from "../definitions/model/User";
import { useUser, useUnit } from "../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { TextFieldConnector } from "../components/Base/FormConnector";
import CircularProgressButton from "../components/Base/CircularProgressButton";
import { styled } from "@mui/material/styles";
import MyHouseImage from "./../assets/img/myHouseImage.jpg";
import ClimatePlanImage from "./../assets/img/water.jpg";
import { useUserTheme } from "../helpers/hooks";
import AddressHelper from "../helpers/address-helper";

const Header = styled(Grid)(({ theme }) => ({
  height: 80,
  backgroundColor: theme.palette.primary.main,
  "& div": {
    color: theme.palette.primary.contrastText,
  },
}));

const BannerGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== "climatePlan",
})<{ climatePlan: boolean }>(({ theme, climatePlan }) => ({
  backgroundImage: climatePlan ? `url(${ClimatePlanImage})` : `url(${MyHouseImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
}));

const AboutPartContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.gray,
  padding: "20px 0",
  [theme.breakpoints.only("sm")]: {
    marginTop: 30,
    padding: "20px 0",
  },
}));

const AutocompleteLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: 10,
  margin: "0 0 2px 15px",
}));

interface Indexable {
  [key: string]: any;
}

type CompleteRegistrationFormData = {
  Name: string;
  Email: string;
  Password: string;
  UnitId: string;
};

const CompleteUserRegistration = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [valueCallback, setValueCallback] = useState("");
  const [openPolitic, setOpenPolitic] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [{ user, authDestination }, { completeRegistration }] = useUser();
  const [{ unit }, { getUnit }] = useUnit();
  const { t } = useTranslation("translation");
  const userTheme = useUserTheme();

  const isClimatePlan =
    (userTheme as any)["name"] === "communeTheme" || (userTheme as any)["name"] === "commercialTheme";

  const methods = useForm<CompleteRegistrationFormData & UserInterestsFormData>({
    defaultValues: {
      Name: state?.Name || user?.Name,
      Email: state?.Email || user?.Email,
      Password: state?.Password,
      UnitId: state?.UnitId,
      DIY: false,
      Culture: false,
      Accommodation: false,
      Garden: false,
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;
  useEffect(() => {
    setValue("UnitId", valueCallback);
  }, [valueCallback]);

  useEffect(() => {
    register("UnitId", { required: true });
    return () => unregister("UnitId"); // unregister input after component unmount
  }, [register, unregister]);

  useEffect(() => {
    getUnit(valueCallback).catch(() => {
      console.log("error");
    });
  }, [valueCallback]);

  useEffect(() => {
    if (unit) {
      const autocompleteValue = AddressHelper.GetAutocompleteInput(unit.Address);
      setInputValue(autocompleteValue);
    }
  }, [unit]);

  const submit = (formData: CompleteRegistrationFormData & UserInterestsFormData) => {
    const data: CompleteRegistrationData = {
      Name: formData.Name,
      Email: formData.Email,
      Password: formData.Password,
      UnitId: formData.UnitId,
      Interests: [],
      IgnoreNotifications: false,
    };

    Object.entries(formData)
      .filter(([key, value]) => typeof value === "boolean" && value)
      .map(([key, value]) => key)
      .forEach((key) => {
        data.Interests.push((UserInterest as Indexable)[key]);
      });

    if (unit) {
      setLoading(true);
      completeRegistration(data)
        .then(() => navigate(authDestination ?? "/overview"))
        .finally(() => setLoading(false));
    }
  };
  const onPoliticClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenPolitic(true);
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      sx={(theme) => ({ color: theme.palette.primary.dark, minHeight: "100vh" })}
    >
      <Header container item alignItems={"center"} justifyContent={"center"}>
        <Typography component="div" variant="h4">
          {t("ServiceBook.Workers.WelcomeTo")} {t("Footer.Myhouse", { hostname: window.location.hostname })}
        </Typography>
      </Header>
      <BannerGrid container climatePlan={isClimatePlan} justifyContent="center" sx={{ flex: 1 }}>
        <Grid container item xs={10} justifyContent="center" alignItems="center">
          <Card sx={{ minWidth: isXs ? "340px" : "400px", margin: "30px 0" }}>
            <CardContent sx={{ backgroundColor: theme.palette.background.default }}>
              <form onSubmit={handleSubmit(submit)}>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#4a4a4a" }}>{t("ServiceBook.Workers.ContactInformation")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <TextFieldConnector
                      register={register("Name", {
                        required: true,
                        minLength: 3,
                      })}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        size="small"
                        label={t("Authorization.Forms.Name")}
                        error={!!errors.Name}
                      />
                    </TextFieldConnector>
                  </Box>
                  <Box mb={2}>
                    <TextFieldConnector
                      register={register("Email", {
                        required: true,
                        pattern: emailRegexp,
                      })}
                    >
                      <TextField
                        disabled={!user?.FaceBookId}
                        variant="outlined"
                        fullWidth
                        type="email"
                        size="small"
                        label={t("Authorization.Forms.EnterName")}
                        error={!!errors.Email}
                      />
                    </TextFieldConnector>
                  </Box>
                  {!user?.FaceBookId && (
                    <Box mb={1}>
                      <TextFieldConnector
                        register={register("Password", {
                          required: true,
                          minLength: 6,
                        })}
                      >
                        <PasswordField
                          variant="outlined"
                          fullWidth
                          size="small"
                          label={t("Authorization.Forms.Password")}
                          error={!!errors.Password}
                        />
                      </TextFieldConnector>
                    </Box>
                  )}
                  <Box mb={1}>
                    <AutocompleteLabel>{t("Authorization.Forms.AddressAndCity")}</AutocompleteLabel>
                    <AutoComplete
                      placeholder={t("Autocomplete.Placeholder")}
                      setValueCallback={setValueCallback}
                      inputValue={inputValue}
                      className={`${errors.UnitId ? "Mui-error Mui-error" : ""}`}
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                <Typography>Mine interesser</Typography>
                <UserInterestsPart methods={methods} />
              </Grid> */}
                <Grid container mb={1} item alignItems="center" justifyContent="center">
                  <Link
                    underline="always"
                    variant="body2"
                    sx={{ color: "#4a4a4a", cursor: "pointer" }}
                    onClick={onPoliticClick}
                  >
                    {t("ServiceBook.Workers.OurPersonalDataPolicy")}
                  </Link>
                </Grid>
                {/*{openHasFamilyAlert && (*/}
                {/*    <Fade in={openHasFamilyAlert}>*/}
                {/*        <Grid item container md={6} sm={12} xs={12} justifyContent="center">*/}
                {/*            <Grid item>*/}
                {/*                <Alert severity="warning">*/}
                {/*                    Der er brugere tilknyttet adressen. <br />*/}
                {/*                    Tryk på &quot;Næste&quot; for at sende en anmodning,*/}
                {/*                    hvis du vil flytte ind.*/}
                {/*                </Alert>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Fade>*/}
                {/*)}*/}
                <Grid item container justifyContent="center">
                  <CircularProgressButton
                    color="secondary"
                    type="submit"
                    inProgress={loading}
                    circularProgressProps={{
                      color: "inherit",
                      size: "small",
                    }}
                  >
                    {t("General.Buttons.Next")}
                  </CircularProgressButton>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </BannerGrid>
      <AboutPartContainer container item justifyContent="center">
        <Grid item p={2} container justifyContent="center">
          <Grid item md={4} sm={6} xs={12}>
            {isClimatePlan ? (
              <Typography variant="body2">
                <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.ValidateDialog.PurposeDescription" />
              </Typography>
            ) : (
              <Typography component="div" variant="body2">
                <Box mb={2.5}>{t("Pages.AboutUs.About", { hostname: window.location.hostname })}</Box>
                <Box mb={2.5} bgcolor="">
                  {t("ServiceBook.Workers.InMyhouseYouCanFindPublicInformation", {
                    hostname: window.location.hostname,
                  })}
                </Box>
                <Box>{t("ServiceBook.Workers.JoinClubsAndPursueYourInterests")}</Box>
              </Typography>
            )}
          </Grid>
        </Grid>
      </AboutPartContainer>
      <DataPoliticDialog open={openPolitic} closeDialog={() => setOpenPolitic(false)} />
    </Grid>
  );
};

export default CompleteUserRegistration;
