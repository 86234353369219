import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Tender } from "../../../../definitions/model/ServiceBook";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import { convertToCurency } from "../../../../services/converter.service";
import { Archive, MoreVert } from "@mui/icons-material";
import { useServicebook } from "../../../../actions/servicebook.actions";
import { set } from "lodash";

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
  },
}));

type TenderCardProps = {
  tender: Tender;
  onSelect: (id: string) => void;
  archive?: boolean;
};

const TenderCard = (props: TenderCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [, actions] = useServicebook();

  const handleChangeTenderClick = (reportId: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    props.onSelect(reportId);
    handleClose(e);
  };
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleArchiveTenderClick = (tenderId: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    actions.archiveTender(tenderId).finally(() => {
      setLoading(false);
    });
    handleMenuClose(e);
  };

  return (
    <StyledCard variant="outlined" onClick={handleChangeTenderClick(props.tender.Id)}>
      <CardHeader
        title={`${props.tender.Name}`}
        //subheader={tender.CreatedBy}
        action={
          !props.archive && (
            <IconButton size="small" onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
          )
        }
      />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem sx={{ padding: "4px" }} onClick={handleArchiveTenderClick(props.tender.Id)}>
          <Archive />
          {t("ServiceBook.Tender.MoveToArchive")}
        </MenuItem>
      </Menu>
      <CardContent>
        {loading && <LinearProgress variant="indeterminate" color="secondary" />}
        <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
          <Typography variant="h4">{new Date(props.tender.Created).toLocaleDateString("da-dk")}</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="caption">{t("ServiceBook.Tender.StandardPrice")}</Typography>
          <Grid container justifyContent="space-between"></Grid>
          <Typography>{convertToCurency(props.tender.StandardPrice, ".")}</Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="caption">
            <Trans components={{ sub: <sub /> }} i18nKey="ServiceBook.Tender.CO2FootprintKilograms" />
          </Typography>
          <Grid container justifyContent="space-between"></Grid>
          <Typography>{convertToCurency(props.tender.CO2FootprintKilograms, ".")}</Typography>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default TenderCard;
