import React from "react";
import { matchPath, Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import MainContainer from "../../components/Layout/MainContainer";
import BuildingsManagement from "./BuildingsManagement";
import BuildingPage from "./BuildingPage";
import BuildingUserLogMessages from "./BuildingUserLogMessages";
import BuildingJoinPage from "./BuildingJoinPage";
import BuildingMembersPage from "./components/BuildingMembersPage";
import BuildingWorkersPage from "../Workers/BuildingWorkersPage";
import FindBuildingWorkersPage from "../Workers/FindBuildingWorkersPage";
import BuildingDocumentsPage from "./BuildingDocumentsPage";
import BuildingUnitsPage from "./BuildingUnitsPage";
import BuildingManagerPage from "./BuildingManagerPage";
import { useUser } from "../../actions/user.actions";
import BuildingArchivePage from "./BuildingArchivePage";
import { RouteType } from "../../routes";
import BuildingTendersPage from "./BuildingTendersPage";
import BuildingServiceBook from "./BuildingServiceBook";
import EditTender from "./Tender/EditTender";
import BuildingTendersArchivePage from "./BuildingTendersArchivePage";

const SearchBuildingPageLazy = React.lazy(() => import("../../pages/Search/SearchBuildingPage"));

export const buildingRoutes: RouteType[] = (() => {
  return [
    { title: "BuildingManagement.BuildingsList", path: "", element: BuildingsManagement },
    { title: "Building.BuildingPage", path: ":addressURL", element: BuildingPage },
    { title: "BuildingManagement.EditMembers", path: ":id/members", element: BuildingMembersPage },
    { title: "SearchResult.BuildingSearch", path: "search", element: SearchBuildingPageLazy },
    {
      title: "Building.BuildingJoinTitle",
      path: ":addressURL/join/:token",
      element: BuildingJoinPage,
      allowAnonymous: true,
    },
    { title: "Building.BuildingWorkers", path: ":addressURL/workers", element: BuildingWorkersPage },
    {
      title: "ServiceBook.Workers.FindCraftsmen",
      path: ":addressURL/workers/find",
      element: FindBuildingWorkersPage,
      parentPath: "/buildings/:addressURL/workers",
    },
    { title: "Building.BuildingPage", path: ":addressURL/users-log", element: BuildingUserLogMessages },
    { title: "Building.BuildingDocuments", path: ":addressURL/documents", element: BuildingDocumentsPage },
    { title: "Building.BuildingFlats", path: ":addressURL/flats", element: BuildingUnitsPage },
    { title: "Building.BuildingManager", path: ":addressURL/admin", element: BuildingManagerPage },
    { title: "Building.BuildingTenantsArchive", path: ":addressURL/archive", element: BuildingArchivePage },
    { title: "Building.Tenders", path: ":addressURL/tenders", element: BuildingTendersPage },
    { title: "Building.ServiceBook", path: ":addressURL/servicebook", element: BuildingServiceBook },
    {
      title: "ServiceBook.Tender.CreateNewTender",
      path: ":addressURL/tenders/new",
      element: EditTender,
      parentPath: ":addressURL/tenders",
    },
    {
      title: "ServiceBook.Tender.TendersArchive",
      path: ":addressURL/tenders/archive",
      element: BuildingTendersArchivePage,
      parentPath: ":addressURL/tenders",
    },
    {
      title: "ServiceBook.Tender.EditTender",
      path: ":addressURL/tenders/:tenderId",
      element: EditTender,
      parentPath: "/buildings/:addressURL/tenders",
    },
    {
      title: "ServiceBook.Tender.PreviewTender",
      path: ":addressURL/tenders/archive/:tenderId",
      element: EditTender,
      parentPath: "/buildings/:addressURL/tenders",
    },
  ];
})();

const BuildingLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation("translation");
  const [{ uiMode }, userActions] = useUser();

  const getPageTitleFromUrl = (pathname: string) => {
    const currentRoute = buildingRoutes.find((r) => {
      if (matchPath({ path: "/buildings/" + r.path }, pathname)) {
        return true;
      }

      return false;
    });

    return currentRoute?.title ? t(currentRoute.title) : "";
  };

  React.useEffect(() => {
    if (uiMode !== "building") {
      userActions.setUiMode("building");
    }
  }, [pathname]);

  return (
    <MainContainer title={getPageTitleFromUrl(pathname)}>
      <Container maxWidth="md">
        <Outlet />
      </Container>
    </MainContainer>
  );
};

export const OldInviteBuildingRedirect = () => {
  const { addressURL, token } = useParams<{ addressURL: string; token: string }>();

  return <Navigate to={`/buildings/${addressURL}/join/${token}`} />;
};

export default BuildingLayout;
